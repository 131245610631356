import type { AxiosResponse } from "axios";
import axios from "axios";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { Field } from "types/model/field";
import type { FieldOption } from "types/model/field-option";
import type { HomeTab } from "types/model/home-tab";
import type { Venue } from "types/model/venue";

export const getHomeTabs = async () => {
  const response: AxiosResponse<HomeTab<Field, FieldOption, Venue>[]> =
    await axios.get("/api/home-tabs");

  return response.data;
};

export default function useHomeTabs(): QueryResult<
  HomeTab<Field, FieldOption, Venue>[]
> {
  return useQuery(["homeTabs"], getHomeTabs);
}
