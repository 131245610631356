import type { AxiosResponse } from "axios";
import axios from "axios";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";

export interface BasketActivitiesData {
  activityIds: string[];
}

const getBasketActivities = async (): Promise<BasketActivitiesData> => {
  const response: AxiosResponse<BasketActivitiesData> = await axios.get(
    "/api/basket/activities"
  );

  return response.data;
};

export default function useBasketActivities(): QueryResult<{
  activityIds: string[];
}> {
  return useQuery(["basketActivities"], getBasketActivities);
}
