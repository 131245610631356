const HomeFiltersSkeleton = () => {
  return (
    <div>
      <div className="flex animate-pulse flex-col gap-6">
        <div>
          <div className="mb-1 h-5 w-24 rounded bg-gray-300"></div>
          <div className="h-9 rounded bg-gray-300"></div>
        </div>
        <div>
          <div className="mb-1 h-5 w-24 rounded bg-gray-300"></div>
          <div className="h-9 rounded bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
};

export default HomeFiltersSkeleton;
