import type { AxiosResponse } from "axios";
import axios from "axios";
import type { InfiniteQueryResult } from "react-query";
import { useInfiniteQuery } from "react-query";
import type { GetActivitySessionsListingResponse } from "types/model/activity";

export interface BasketActivitiesData {
  activityIds: string[];
}

const fetchActivitySessionsListing = async (
  nextPageOffset = 0,
  filtersStringified = "{}",
  listingPath: string
): Promise<GetActivitySessionsListingResponse> => {
  const response: AxiosResponse<GetActivitySessionsListingResponse> =
    await axios.get(
      `/api/activities/activity-sessions-listing?cursor=${nextPageOffset}&filters=${filtersStringified}${
        listingPath ? `&listingPath=${listingPath}` : ""
      }`
    );

  return response.data;
};

export default function useActivitySessionsListing(
  filtersStringified: string,
  listingPath: string
): InfiniteQueryResult<GetActivitySessionsListingResponse> {
  return useInfiniteQuery(
    `activitySessionsListing_${filtersStringified}_${listingPath}`,
    (_, nextPageOffset: number) =>
      fetchActivitySessionsListing(
        nextPageOffset,
        filtersStringified,
        listingPath
      ),
    {
      getFetchMore: lastGroup => lastGroup.nextPageOffset
    }
  );
}
