import { CalendarIcon } from "@heroicons/react/24/outline";
import Badge from "components/Badge";
import { formatDate, formatTime, getIsSameDay } from "helpers/date";
import { getActivityTitle, getActivityVenueName } from "helpers/helpers";
import Link from "next/link";
import IconClock from "public/images/icons/clock.svg";
import IconLocation from "public/images/icons/location.svg";
import { OverallAvailability } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Client } from "types/model/client";

type Props = {
  activityGroup: ActivityGroup;
  isIframe: boolean;
  overallAvailability?: OverallAvailability;
  client: Client;
};

const ActivityListItem = ({
  activityGroup,
  isIframe,
  overallAvailability,
  client
}: Props) => {
  return (
    <Link
      href={`/activity/${activityGroup._id}`}
      target={isIframe ? "_blank" : "_self"}
      passHref
    >
      <div className="flex h-full flex-col justify-between text-indigo-600">
        <div className="p-4">
          <h3 className="mb-6 border-b border-gray-300 pb-4 text-base font-medium text-indigo-600">
            {getActivityTitle(activityGroup)}{" "}
            {activityGroup.exampleActivity && (
              <span className="ml-2 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                Example
              </span>
            )}
          </h3>
          <div className="space-y-4">
            <div className="flex items-start text-sm text-gray-500">
              <CalendarIcon
                width={20}
                height={20}
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              />
              {formatDate(
                activityGroup.activities[0].date.start,
                "E d MMM",
                client.timeZone
              )}
              {activityGroup.activities.length > 1 &&
                !getIsSameDay(
                  activityGroup.activities[0].date.start,
                  activityGroup.activities[activityGroup.activities.length - 1]
                    .date.end,
                  client.timeZone
                ) && (
                  <>
                    {" - "}
                    {formatDate(
                      activityGroup.activities[
                        activityGroup.activities.length - 1
                      ].date.end,
                      "E d MMM",
                      client.timeZone
                    )}
                  </>
                )}
            </div>
            <div className="flex items-start text-sm text-gray-500">
              <IconClock
                width={20}
                height={20}
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              />
              <div>
                {activityGroup?.timePeriods &&
                activityGroup?.timePeriods?.length > 2
                  ? "Various times"
                  : activityGroup.timePeriods?.map((timePeriod, index) => (
                      <span key={index}>
                        {index > 0 ? ", " : ""}
                        {formatTime(
                          timePeriod.startTimeHours,
                          timePeriod.startTimeMinutes
                        ).toLowerCase()}
                        {" - "}
                        {formatTime(
                          timePeriod.endTimeHours,
                          timePeriod.endTimeMinutes
                        ).toLowerCase()}
                      </span>
                    ))}
              </div>
            </div>
            {getActivityVenueName(activityGroup) && (
              <div className="flex items-start text-sm text-gray-500">
                <IconLocation
                  width={20}
                  height={20}
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                />
                {getActivityVenueName(activityGroup)}
              </div>
            )}
          </div>
        </div>
        <div className="px-4 pb-5">
          {overallAvailability === OverallAvailability.SoldOut ? (
            <Badge cy="availability-badge" color="red">
              Sold out
            </Badge>
          ) : overallAvailability === OverallAvailability.NoTicketsAvailable ? (
            <Badge cy="availability-badge" color="gray">
              Unavailable
            </Badge>
          ) : overallAvailability === OverallAvailability.Available ? (
            <Badge cy="availability-badge" color="green">
              Places available
            </Badge>
          ) : overallAvailability === OverallAvailability.Limited ? (
            <Badge cy="availability-badge" color="yellow">
              Limited availability
            </Badge>
          ) : (
            <div className="animate-pulse">
              <div
                className="h-4 w-5/6 rounded-full bg-gray-300"
                style={{ width: 71 }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ActivityListItem;
